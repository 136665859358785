<script setup>
import Button from '@/Components/Button.vue';
import { computed } from 'vue';

const props = defineProps({
  totalResults: {
    type: Number,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
  queryObject: {
    type: Object,
    required: true,
  },
  slug: {
    type: String,
    required: true,
  }
});
const totalPages = computed(() => {
  return Math.ceil(props.totalResults / 10);
});
const queryData = computed(() => {
  if(!props.queryObject.page) {
    return {...props.queryObject, page: 1};
  }
  return props.queryObject;
});
</script>


<template>
  <div class="w-full flex justify-around">
    <div class="hidden md:block">
      <Button
        v-if="currentPage > 1"
        aria-label="First page"  
        class="mx-2 text-grey" 
        variant="no-style" 
        type="link"  
        :data="{...queryData, page: 1}"
        :link="`/${slug}`"
      >
        First
      </Button>
      <Button
        v-if="currentPage > 1"
        aria-label="Previous page"  
        class="mx-2 text-grey" 
        variant="no-style" 
        type="link" 
        :data="{...queryData, page: currentPage - 1}"
        :link="`/${slug}`"
      >
        Previous
      </Button>
    </div>
    <div class="flex">
      <Button
        v-if="currentPage > 2"
        :aria-label="`Page ${currentPage - 2}`" 
        class="mx-2"
         
        :link="`/${slug}`" 
        :data="{...queryData, page: currentPage - 2}" 
        variant="pagination" 
        type="link"
      >
        {{ currentPage - 2 }}
      </Button>
      <Button
        v-if="currentPage > 1"
        :aria-label="`Page ${currentPage - 1}`" 
        class="mx-2"
        :link="`/${slug}`" 
        :data="{...queryData, page: currentPage - 1}" 
        variant="pagination" 
        type="link"
      >
        {{ currentPage - 1 }}
      </Button>
      <Button
        :aria-label="`Page ${currentPage}`"
        class="mx-2 bg-primary text-white" 
        variant="pagination" 
        type="link"
        :data="{...queryData}" 
        :link="`/${slug}`"
      >
        {{ currentPage }}
      </Button>
      <Button
        v-if="(currentPage + 1) <= totalPages"
        :aria-label="`Page ${currentPage + 1}`" 
        class="mx-2" 
        :link="`/${slug}`" 
        :data="{...queryData, page: currentPage + 1}" 
        variant="pagination" 
        type="link"
      >
        {{ currentPage + 1 }}
      </Button>
      <Button
        v-if="(currentPage + 2) <= totalPages"
        :aria-label="`Page ${currentPage + 2}`" 
        class="mx-2" 
        :link="`/${slug}`" 
        :data="{...queryData, page: currentPage + 2}" 
        variant="pagination" 
        type="link"
      >
        {{ currentPage + 2 }}
      </Button>
    </div>
     
    <div class="hidden md:block">
      <Button
        v-if="currentPage < totalPages"
        aria-label="Next page"  
        class="mx-2 text-grey" 
        variant="no-style" 
        type="link" 
        :data="{...queryData, page: currentPage + 1}" 
        :link="`/${slug}`"
      >
        Next
      </Button>
      <Button
        v-if="totalPages > 3"
        aria-label="Last page"  
        class="mx-2 text-grey" 
        variant="no-style" 
        type="link" 
        :data="{...queryData, page: totalPages}" 
        :link="`/${slug}`"
      >
        Last
      </Button>
    </div>
  </div>
</template>